.grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
